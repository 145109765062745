<template>
    <div>
        <el-dialog title="创建幻灯" :visible="adSlideShow" width="1000px" @close="cancle" style="margin-top:50px" v-loading="loading" :append-to-body="true">
            <el-form v-model="slideForm">
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="单张图片显示时间(单位：ms;1s=1000ms)" prop="durationMs">
                            <el-input v-model="slideForm.durationMs" type="number">
                                <template slot="append">ms</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="图片淡入淡出时间(单位：ms;1s=1000ms)" prop="transitionMs">
                            <el-input v-model="slideForm.transitionMs" type="number">
                                <template slot="append">ms</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                
            </el-form>
            <p>
                <span>选择素材</span>
                <!-- <el-button  style="margin-left: 50px" size="mini" type="text" @click="reset">重置排序</el-button> -->
                <span style="margin-left:20px"  v-show="imgLists.length<10">
                    <!-- <el-button  size="small" @click="selectImg">从创意云选择</el-button> -->
                    <el-upload class="upload-demo slide-upload" action="#"  :show-file-list="false" list-type="picture-card" :auto-upload="false" multiple :limit ='10' :before-upload="beforeUpload" :on-change="selectImgUpload" :on-exceed="exceedFun" :on-remove="removeFile" :file-list="JSON.parse(JSON.stringify(imgLists))">
                        <el-button size="small" type="primary">本地上传</el-button>
                    </el-upload>
                    <span class="tips" style="margin-left:10px">上传图片宽高必须是偶数</span>
                </span>
            </p>
            <div class="imgList" v-if="imgLists.length">
                <draggable class="list-group" tag="ul" v-model="imgLists" v-bind="dragOptions" :move="onMove" @start="isDragging=true" @end="isDragging=false">
                    <transition-group type="transition" :name="'flip-list'">
                        <li class="list-group-item" v-for="(element,index) in imgLists" :key="index+''">
                            <div class="imgCon">
                                <el-image :src="element.url" fit="contain"></el-image>
                                <i class="el-icon-close" @click.stop="deleImg(index,element.id)"></i>
                            </div>
                            
                        </li>
                    </transition-group>
                </draggable>
            </div>
            <div v-else>
                <div class="empty">
                    <i class="iconfont icon-wushuju"></i>
                    <p style="margin:top:15px">暂无图片</p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancle">取 消</el-button>
                <el-button type="primary" @click="creatSlide">确 定</el-button>
            </span>
        </el-dialog>
        <img-list :imgListShow="imgListShow" :saveAdFileParam="saveAdFileParam" @close="close" @urlList="urlList" uploadTypes="img" :selectList="sorcesIds"></img-list>
    </div>
</template>
<script>
import imgList from './imgList'
import draggable from 'vuedraggable'
import {upload,generateSlideshowVideo,querySlideshowVideo,generateSlideshowVideoNew,saveMedia,mediaFileUploadFb} from '@/api/creatAd.js'
    export default {
        props:['adSlideShow','saveAdFileParam'],
        data() {
            return {
                slideForm:{
                    durationMs:1000,
                    transitionMs:50
                },
                imgLists:[],
                sorcesIds:[],
                imgListShow:false,
                loading:false,
                editable: true,
                isDragging: false,
                delayedDragging: false,
                uploadNum:0
            }
        },
        components:{
            imgList,
            draggable
        },
        methods: {
            // 点解关闭
            close(v){
                this[v] = false;
                this.imgLists = [];
            },
            // 点击取消
            cancle(){
                // this.imgLists = [];
                this.imgLists = [];
                this.sorcesIds = [];
                this.$emit('close','adSlideShow')
            },
            unique(arr){
                var hash = {};
                let array = arr.reduce(function(item, next) {
                    hash[next.name] ? '' : hash[next.name] = true && item.push(next);
                    return item
                },[])
                return array
            },
            beforeUpload(file){
                let isJPG = true;
                isJPG = (file.type == 'image/jpeg'||file.type == 'image/png' ||file.type == 'image/jpg'||file.type == 'image/ioc' ||file.type == 'image/bmp');
                if(!isJPG){
                    this.$message.warning('请上传正确图片格式');
                    return false;
                }
            },
            // 监听拖拽
            onMove({ relatedContext, draggedContext }) {
                const relatedElement = relatedContext.element;
                const draggedElement = draggedContext.element;
                return (
                    (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
                );
            },
            // 点击确定
            creatSlide(){
                console.log(this.imgLists);
                if(this.imgLists.length<3){
                    this.$message({
                        type:'warning',
                        message:'请至少选择三张图片'
                    })
                    return false;
                }
                let formData = new FormData();
                formData.append('durationMs',this.slideForm.durationMs)
                formData.append('transitionMs',this.slideForm.transitionMs)
                console.log(this.imgLists);
                this.imgLists.forEach((item,index)=>{
                    console.log(item);
                    formData.append(`file`,item.raw)
                })
                console.log(formData);
                // let params = {mediaFileIds:this.imgLists.map(v=>v.id),accountId:this.$store.getters.accountId,...this.slideForm}
                this.$showLoading();
                generateSlideshowVideoNew(formData,{durationMs:this.slideForm.durationMs,transitionMs:this.slideForm.transitionMs}).then(res=>{
                    // console.log(res);
                    this.$hideLoading();
                    if(res.code == 0){
                        this.uploadNum = 0;
                        res.data.sourceType = 'SELF_MATERIAL';
                        this.$emit('getVideoFile',res.data)
                    }else{
                        this.$hideLoading()
                    }
                })
            },
            // getVideoUrl(id){
            //     querySlideshowVideo({videoId:id,accountId:this.$store.getters.accountId}).then(res=>{
            //         if (res.code == 0) {
            //             if(res.data.videoStatus == 'processing'){
            //                 if(this.uploadNum >=36){
            //                     this.$confirm('取消重试或者继续等待?', '提示', {
            //                         confirmButtonText: '取消重试',
            //                         cancelButtonText: '继续等待',
            //                         type: 'warning'
            //                     }).then(() => {
            //                         // 取消重试
            //                         this.$hideLoading();
            //                         this.uploadNum = 0;
            //                         return;
            //                     }).catch(() => {
            //                         // 继续等待
            //                         this.uploadNum = 0;   
            //                         setTimeout(()=>{this.getVideoUrl(id),this.uploadNum+=1},5000);   
            //                     });
            //                 }else{
            //                     setTimeout(()=>{this.getVideoUrl(id),this.uploadNum+=1},5000);
            //                 }
                            
            //             }else if(res.data.videoStatus == 'ready'){
            //                 this.$hideLoading();
            //                 this.$emit('setVideoUrl',res.data.mediaFile,res.data.thumbnails);
            //             }else{
            //                 this.$hideLoading();
            //                 this.$message({
            //                     type:'warning',
            //                     message:'失败'
            //                 })
            //             }
            //         }else{
            //             this.$hideLoading();
            //             this.$message({
            //                 type:'warning',
            //                 message:'失败'
            //             })
            //         }
            //     })
            // },
            setIndex(id){
                // 删除排序
                if(this.sorcesIds.indexOf(id)>=0){
                    this.sorcesIds.splice(this.sorcesIds.indexOf(id),1)
                }else{
                    this.sorcesIds.push(id);
                }
                
            },
            // 重置排序
            reset(){
                this.sorcesIds = this.imgLists.map(v=>v.id)
            },
            deleImg(index,id){
                this.imgLists.splice(index,1);
                console.log(this.imgLists)
                // if(this.sorcesIds.indexOf(id)>=0){
                //     this.sorcesIds.splice(this.sorcesIds.indexOf(id),1)
                // }
                // this.removeFile(this.imgLists[index])
            },
            removeFile(file,fileList){

            },
            selectImg(index){
                this.imgListShow = true;
            },
            urlList(v){
                console.log(v);
                let list = JSON.parse(JSON.stringify(v))
                list.map((item,index)=>{
                    item.fixed = false;
                })
                this.imgLists = this.imgLists.concat(list).slice(0,10);
                console.log(this.imgLists);
                // this.sorcesIds = this.sorcesIds.concat(v.map(m=>m.id)).slice(0,7);
                // this.sorcesIds = [...new Set(this.sorcesIds)]
            },
            selectImgUpload(file,fileList){
                console.log(file,fileList);
                this.imgLists.push(file);
                console.log(this.imgLists);
            },
            exceedFun(file,fileList){
                console.log(fileList.length)
                this.$message({
                    type:'info',
                    message:'文件超过限制，最多上传10张图片哦~~'
                })
            },
            uploadImg(file){
                console.log(file);
                this.imgLists.push({url:res.data.url,id:res.data.id,fixed:false})
                // let formData = new FormData();
                // formData.append("file", file.file);
                // formData.append("type", 'IMAGE');
                // formData.append("isSlideshow", true);
                // this.$showLoading();
                // upload(formData).then(res => {
                //     this.$hideLoading();
                //     if (res.code == 0) {
                //         this.$message({
                //             type: "success",
                //             message: "上传成功"
                //         });
                //        this.imgLists.push({url:res.data.url,id:res.data.id,fixed:false})
                //        this.imgLists = this.imgLists.slice(0,10);
                //        this.sorcesIds.push(res.data.id)
                //     //    this.imgLists = this.unique(this.imgLists).slice(0,7);
                //     }
                // });
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: !this.editable,
                    ghostClass: "ghost"
                };
            },
        },
    }
</script>
<style lang="scss">
   .slide-upload .el-upload--picture-card{
    width: auto;
    height: auto;
    line-height: normal;
    border: none;
}
    // 弹框最大高度限制
.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  max-height: 98%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<style lang="scss" scoped>
.upload-demo{
    display: inline-block;
    margin-left: 20px;
}
.flip-list-move {
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}
.no-move {
    -webkit-transition: -webkit-transform 0s;
    transition: -webkit-transform 0s;
    transition: transform 0s;
    transition: transform 0s, -webkit-transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.list-group-item {
    cursor: move;
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;

}
    .imgList{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .imgItem{
            width: 170px;
            height: 170px;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #ddd;
            // float: left;
            margin: 10px;
            .imgCon{
                text-align: center;
                .el-button{
                    margin-bottom: 10px;
                }
            }
        }
        ul{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li{
                width: 170px;
                height: 170px;
                position: relative;
                margin: 10px;
                border: 1px solid #ddd;
                .imgCon{
                    width: 100%;
                    height: 100%;
                }
                .el-image{
                    width: 100%;
                    height: 100%;
                }
                i{
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    background: #333;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 16px;
                    color: #fff;
                    cursor: pointer;
                }
                .index{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    background: #409eff;
                    color: #fff;
                    text-align: center;
                    line-height: 20px;
                }
            }
            
        }
    }
</style>